.arogga-btn {
	background-color: #fff;
	border: 1px solid color(grey200);
	padding: 10px 16px;
	font-size: $default-font-size;
	font-weight: 400;
	height: 48px;
	border-radius: 5px;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
	position: relative;

	&.veriant {
		height: 48px;

		&:hover {
			background-color: white;
			color: getColor(grey600);
			border: 1px solid color(grey300);
			transition: 0.2s 0.1s;
		}
	}
	&.veriant-active {
		height: 48px;
		border: 1px solid color(primary);
		background-color: #e7f1f1;
		color: getColor(primary);
		&:hover {
			background-color: white;
			color: getColor(grey600);
			border: 1px solid color(grey300);
			transition: 0.2s 0.1s;
		}
	}
	// add a image here
	&.small {
		font-size: 12px;
		padding: 5px 10px;
		height: 26px;
	}

	&.medium {
		height: 40px;
	}

	&.large {
		font-size: 18px;
		padding: 15px 30px;
		height: 48px;
	}

	&.rounded {
		border-radius: 50px; // Adjust as required
	}
	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;
		background-color: getColor(grey400) !important;
		color: white !important;
		cursor: not-allowed;
		border: 1px solid #ccc !important;
		&:hover {
			background-color: getColor(grey400) !important;
			cursor: not-allowed;
			border: 1px solid getColor(grey300) !important;
		}
	}
	&:hover {
		background-color: #000;
		color: #fff;
	}
	&:focus {
		outline: none;
	}
	// &:disabled {
	// 	background-color: #ccc;
	// 	color: #000;
	// 	cursor: not-allowed;
	// 	border: 1px solid #ccc !important;
	// 	pointer-events: none;
	// }
	&.light {
		background-color: white;
		color: getColor(greyD);
		border: 1px solid color(grey200);
		&:hover {
			background-color: getColor(grey200);
			color: getColor(greyD);
			border: 1px solid color(grey300);
			transition: 0.2s 0.1s;
		}
	}
	&.already-cart {
		background-color: white;
		color: getColor(primary);
		font-weight: 700;
		border: 1px solid color(primary);
		&:hover {
			background-color: getColor(white);
			color: getColor(primary);
			border: 1px solid color(primary);
			transition: 0.2s 0.1s;
		}
	}
	&.out-of-stock {
		background-color: transparent;
		color: getColor(error);
		border: 1px solid color(error);
		&:hover {
			background-color: getColor(error);
			border: 1px solid color(error);
			color: #fff;
		}
	}
	&.grey {
		background-color: getColor(grey100);
		color: getColor(grey600);
		border: 1px solid color(grey300);
		&:hover {
			background-color: white;
			color: getColor(grey600);
			border: 1px solid color(grey300);
			transition: 0.2s 0.1s;
		}
	}
	&.register {
		background-color: white;
		@include color(primary);
		border: 1px solid color(grey200);
		&:hover {
			@include color(white);
			@include bgColor(primary, 100);
			border: 1px solid color(grey300);
		}
	}
	&.primary {
		background-color: getColor(base);
		border: 1px solid color(primary);
		color: #fff;
		transition: 0.2s 0.1s;
		&:hover {
			background-color: getColor(primary400);
			color: #fff;
		}
		svg {
			fill: #fff !important;
			color: #fff !important;
		}
	}
	&.primary-outline {
		background-color: transparent;
		color: getColor(primary);
		border: 1px solid color(primary);
		&:hover {
			background-color: getColor(primary400);
			color: #fff;
		}
	}
	&.primary-outline-cart {
		background-color: #e5f3f3;
		color: getColor(primary);
		border: 1px solid color(primary);
		&:hover {
			background-color: getColor(primary400);
			color: #fff;
		}
	}
	&.secondary {
		background-color: getColor(secondary);
		color: #fff;
		border: none;
		&:hover {
			background-color: getColor(secondary);
			color: #fff;
		}
	}
	&.success {
		background-color: getColor(success);
		color: #fff;
		border: none;
		&:hover {
			background-color: getColor(success);
			color: #fff;
		}
	}
	&.warning {
		background-color: getColor(warning200);
		color: #fff;
		border: none;
		&:hover {
			background-color: getColor(orange);
			color: #fff;
		}
	}

	&.danger {
		background-color: getColor(error);
		color: #fff;
		border: none;
		&:hover {
			background-color: getColor(error);
			color: #fff;
		}
	}
	&.danger-outline {
		background-color: transparent;
		color: getColor(error);
		border: 1px solid color(error);
		&:hover {
			background-color: getColor(error);
			color: #fff;
		}
	}

	&.search-outline {
		background-color: getColor(white);
		color: getColor(grey600);
		border: 1px solid color(grey300);
		&:hover {
			background-color: getColor(primary_bg);
			color: getColor(primary);
		}
	}
	&.dark {
		background-color: getColor(dark);
		color: #fff;
		border: none;
		&:hover {
			background-color: getColor(dark);
			color: #fff;
		}
	}
	&.orang-outline {
		background-color: getColor(orange);
		color: getColor(white);
		border: 1px solid color(orange);
		&:hover {
			background-color: orange;
			color: #fff;
		}
	}
	&.order {
		background-color: getColor(p_bg);
		color: getColor(primary);
		border: 1px solid color(primary);
		height: 40px;
		&:hover {
			background-color: getColor(primary400);
			color: #fff;
		}
	}
	&.confirmed {
		background-color: #1554f6;
		color: getColor(white);
		border: 1px solid #0e46d4;
		&:hover {
			background-color: #0e46d4;
			color: #fff;
		}
	}
	.delivered {
		background-color: #1554f6;
		color: getColor(white);
		border: 1px solid #0e46d4;
		&:hover {
			background-color: #0e46d4;
			color: #fff;
		}
	}
	&.general {
		background-color: getColor(grey300);
		color: getColor(grey900);
		border: 1px solid color(grey400);
		&:hover {
			background-color: getColor(grey400);
			color: getColor(grey900);
		}
	}

	.inner {
		@include flexCenter;
		width: 100%;
		height: 100%;
		position: relative;

		span {
			position: relative;
			text-wrap: nowrap;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.icon {
			margin-right: 10px;
		}
	}
	.arogga-btn-text {
		font-size: 16px;
		font-weight: 600;
		color: #000;
	}
}
@keyframes spin {
	0% {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	100% {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
