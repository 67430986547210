@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.model_container {
	background-color: $white;
	display: grid;
	grid-column: '';
	border-radius: 20px;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	position: relative;

	.image_container {
		background-color: #fafbfc;
		border-start-start-radius: 20px;
		border-bottom-left-radius: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		flex: 1;
		padding: 70px 0px 70px 0px;

		.image_content {
			width: 70%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			gap: 12px;

			.image_title {
				font-size: 22px;
				font-weight: bold;
				text-align: center;
				display: flex;
				align-items: center;
				height: 63px;
			}
			.image_description {
				font-size: 14px;
				text-align: center;
				height: 60px;
				color: #5e6f88;
			}
			.image_dots {
				display: flex;
				gap: 8px;

				.dot {
					width: 8px;
					height: 8px;
					border-radius: 50%;
					background-color: #5e6f88;
					cursor: pointer;

					&.active {
						background-color: getColor(primary);
					}
				}
			}
		}
	}
	.login_container {
		padding: 30px 0px 30px 0px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
		.login_content {
			display: flex;
			width: 80%;
			flex-direction: column;
			justify-content: center;
			// padding-inline-start: 30px;
			gap: 10px;
			.login_title {
				font-size: 24px;
				font-weight: bold;
				text-align: left;
				color: getColor(grey900);
			}
			.login_subtitle {
				font-size: 14px;
				color: getColor(grey600);
				padding-bottom: 10px;
				line-height: 1.5;
			}
		}
		.input {
			width: 100%;
			height: 50px;
			border-radius: 10px;
			border: 1px solid getColor(grey300);
			color: getColor(grey600);
			font-size: 16px;

			&::placeholder {
				color: getColor(grey300);
			}
		}
	}
}

.absulate_prefix {
	position: absolute;
	left: 0;
	padding-left: 15px;
	color: getColor(grey900);
	font-weight: 400;
	display: flex;
	align-items: center;
	height: 100%;
}
.prefix {
	color: getColor(grey900);
	font-weight: 400;
	display: flex;
	align-items: center;
	height: 100%;
}
// .input_phone {
// 	&::placeholder {
// 		padding-left: 10px;
// 	}
// }

.disabled {
	color: getColor(grey500);
}
// position: "absolute",
// 											left: 10,
// 											top: "13px",
// 											color: "#1f2937",
// 											fontWeight: 400,
