@import '@/services/scss/variables.scss';

.modalBackdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999999999;
	&.entering,
	&.exiting,
	&.exited {
		opacity: 0;
		pointer-events: none; // Ensure it doesn't capture clicks when invisible
	}

	&.entered {
		opacity: 1;
	}

	transition: opacity 0.3s ease-in-out;
	transition: max-height 0.3s ease-in-out; /* Smooth transition for max-height */
	.modalContent {
		max-height: 100vh;
		transition: max-height 0.3s ease-in-out; /* Smooth transition for max-height */
		background-color: white;
		border-radius: 12px;
		box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
		width: 50%;
		max-width: 90%; // Ensure the modal is responsive and doesn't go off screen
		overflow: hidden;
		overflow-y: scroll;

		&.entering,
		&.exited {
			opacity: 0;
			transform: translateY(-10%); // Slight vertical upward movement
		}

		&.entering-active,
		&.exiting {
			opacity: 1;
			transform: translateY(0);
		}

		&.exiting-active {
			opacity: 0;
			transform: translateY(-10%); // Slight vertical upward movement
		}

		transition:
			opacity 0.3s ease-in-out,
			transform 0.3s ease-in-out;

		.modalHeader {
			padding: 15px 20px;
			border-bottom: 1px solid #e8e8e8;
			font-size: 20px;
			font-weight: 500;
			display: flex;
			justify-content: space-between;
			.closeIcon {
				cursor: pointer;
			}
		}

		.modalBody {
			overflow-y: auto;
			max-height: calc(100vh - 150px);
			transition: max-height 0.3s ease-in-out; /* Smooth transition for max-height */
			transform-origin: top;
			transition: transform 0.3s ease-in-out;
			&::-webkit-scrollbar {
				width: 5px;
				height: 5px;
			}

			&::-webkit-scrollbar-thumb {
				background-color: $primary; /* Color of the scroll thumb */
				border-radius: 20px; /* Roundness of the scroll thumb */
			}
			&:last-child {
				border-bottom: none;
			}
		}

		.modalFooter {
			padding: 15px 20px;
			border-top: 1px solid #e8e8e8;
			display: flex;
			justify-content: flex-end;

			button {
				padding: 6px 15px;
				margin-left: 10px;
				border: none;
				border-radius: 4px;
				cursor: pointer;
				transition: background-color 0.2s;

				&:first-child {
					background-color: #f5f5f5;
					color: #333;

					&:hover {
						background-color: #e5e5e5;
					}
				}

				&:last-child {
					background-color: #1890ff;
					color: #fff;

					&:hover {
						background-color: #40a9ff;
					}
				}
			}
		}
	}
}
