.action_horizontal_container {
	// display: grid;
	// grid-template-columns: repeat(5, 1fr);
	// grid-gap: 25px;
	grid-auto-rows: minmax(100px, auto);
	.warp {
		width: 100%;
		height: 145px;
		flex-shrink: 0;
		border-radius: 12px;
		background: linear-gradient(180deg, #d0e6e8 0%, rgba(208, 230, 232, 0) 100%);
		margin-bottom: 20px;
		position: relative;
		.content {
			padding: 20px;
			padding-top: 30px;
			display: flex;
			justify-content: space-between;
			height: 100%;
			line-height: 24px;
			width: 75%;
			& > div {
				padding-top: 20px;
			}
			h3 {
				margin: 0;
				margin-bottom: 5px;
				font-size: 18px;
				font-weight: 500;
				margin-bottom: 10px;
			}
			.icon_wrap {
				height: 128px;
				position: absolute;
				top: 8%;
				right: -1px;
			}
		}
	}
}

@media (max-width: 1600px) {
	.action_horizontal_container {
		.warp {
			overflow: hidden;
			font-size: 12px;
			.content {
				.icon_wrap {
					height: 100px;
					top: 12%;
					img {
						height: 60px;
						width: 60px;
					}
				}
			}
		}
	}
}
//add all brackpoints
@media (max-width: 1348px) {
	.action_horizontal_container {
		// grid-template-columns: repeat(3, 1fr);
		.warp {
			font-size: 18px;
			.content {
				.icon_wrap {
					height: 128px;
					position: absolute;
					top: 8%;
					right: -1px;
					img {
						height: 80px;
						width: 80px;
					}
				}
			}
		}
	}
}

@media (max-width: 1200px) {
	.action_horizontal_container {
		// grid-template-columns: repeat(2, 1fr);
		.warp {
			font-size: 18px;
			.content {
				.icon_wrap {
					height: 128px;
					position: absolute;
					top: 8%;
					right: -1px;
					img {
						height: 80px;
						width: 80px;
					}
				}
			}
		}
	}
}
@media (max-width: 992px) {
	.action_horizontal_container {
		// grid-template-columns: repeat(2, 1fr);
		.warp {
			font-size: 18px;
			.content {
				.icon_wrap {
					height: 128px;
					position: absolute;
					top: 8%;
					right: -1px;
					img {
						height: 80px;
						width: 80px;
					}
				}
			}
		}
	}
}

@media (max-width: 576px) {
	.action_horizontal_container {
		// grid-template-columns: repeat(1, 1fr);
		.warp {
			font-size: 18px;
			.content {
				.icon_wrap {
					height: 128px;
					position: absolute;
					top: 8%;
					right: -1px;
					img {
						height: 80px;
						width: 80px;
					}
				}
			}
		}
	}
}
