.confirmModal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	.icon {
		height: 100px;
		width: 100px;
		font-size: 50px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.content {
		text-align: center;
		.title {
			font-size: 18px;
			font-weight: 600;
			margin-bottom: 10px;
			color: getColor(grey900);
		}

		.message {
			font-size: 14px;
			font-weight: 400;
			color: #718096;
			line-height: 22.4px;
		}
	}

	.timeline {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.line {
		width: 100px;
		height: 2px;
		background-color: #F9FAFB;
	}
	.line_placed {
		width: 100px;
		height: 3px;
		background-color: #0ba259;
	}
	.green_text {
		color: #0ba259;
		font-size: 14px;
		font-weight: 400;
	}
}

.parent {
	margin-top: 10px;
	display: flex;
	gap: 10px;
	.title {
		color: "#323B49";
		font-size: 14px;
		font-weight: 500;
	}
	.subTitle {
		color: "#718096";
		font-size: 14px;
		font-weight: 400;
		margin-top: 5px;
	}
	.text_styles {
		color: #0E7673;
		font-size: 14px;
		font-weight: 500;
	}
}

.expandContent {
	max-height: 500px; /* Set to a maximum content height */
	overflow: hidden;
	transition: max-height 0.5s ease-out;
}

.collapseContent {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.5s ease-in;
}
