// ScrollToTop.module.scss
@import '@/services/scss/variables.scss';

.scrollToTop {
	position: fixed;
	bottom: 110px;
	right: 38px;
	z-index: 100;
	cursor: pointer;
	animation: fadeIn 0.3s;
	visibility: visible;
	opacity: 0.8;
	background-color: getColor(grey400);
	color: white;
	border: none;
	padding: 10px;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	font-size: 14px;
	svg {
		width: 20px;
		height: 20px;
		fill: getColor(grey900);
	}

	&:hover {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 0.8;
	}
}
