@import './grids/main.scss';
@import './mixin';
@import './variables';
@import './blog';
@import './utils';
@import './card';
@import './sidemenu';
@import './accountmenu';
@import './odometer';
@import './actions/main.scss';
@import './atoms/main.scss';
@import './header';
:root {
	--swiper-theme-color: #007aff;
	--swiper-preloader-color: var(--swiper-theme-color);
	--swiper-wrapper-transition-timing-function: initial;
}
* {
	box-sizing: border-box;
}
.bg-white {
	background-color: #fff !important;
}

//scrollbar
::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-thumb {
	background-color: getColor(primary); /* Color of the scroll thumb */
	border-radius: 20px; /* Roundness of the scroll thumb */
}

body {
	margin: 0;
	scroll-behavior: smooth;
	font-weight: 300;
	background-color: #f9fafb;
}

/* Standard Container */
.container {
	width: 100%;
	max-width: 1320px;
	margin-right: auto;
	margin-left: auto;
}
$breakpoints: (
	576px: 540px,
	768px: 720px,
	992px: 660px,
	1200px: 840px,
	1320px: 960px,
	1400px: 1080px,
	1600px: 1280px
);

.container {
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	max-width: 540px; // default max-width for extra small devices
}

@each $breakpoint, $maxWidth in $breakpoints {
	@media (min-width: $breakpoint) {
		.container {
			max-width: $maxWidth;
		}
	}
}

/* Fluid Container */
.container-fluid {
	width: 100%;
	padding-right: 0px;
	padding-left: 0px;
	margin-right: auto;
	margin-left: auto;
}

// main {
// 	min-height: calc(100vh - 47.4vh);
// 	margin-top: 5px;
// 	background-color: #f9fafb;
// }

.pb-0 {
	padding-bottom: 0 !important;
}

.text-dark {
	color: $dark;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
// 	margin-top: 0;
// 	margin-bottom: 0.5rem;
// 	font-weight: 500;
// 	line-height: 1.2;
// }

a {
	text-decoration: none;
}
ul {
	display: block;
	list-style-type: disc;
	margin-block-start: 1em;
	margin-block-end: 0em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 30px;
}
strong {
	font-weight: 600;
}
input,
textarea,
select,
button {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
//bg colors
.bg_primary {
	background-color: rgba($color: $primary, $alpha: 0.1);
}

.bg_error {
	background-color: rgba($color: $error, $alpha: 0.1);
}
.bg_purple {
	background-color: rgba($color: $purple, $alpha: 0.1);
}

//un known color using
.bg_gl {
	background-color: $un_gray;
}

.thumbSlider .swiper-slide-thumb-active img {
	border: 1px solid #0e7673 !important;
}

@keyframes bounce {
	0%,
	10% {
		transform: translateY(20px); /* Start lower and hold for a short delay */
	}
	30% {
		transform: translateY(-40px); /* First bounce */
	}
	45% {
		transform: translateY(0); /* Back down */
	}
	60% {
		transform: translateY(-30px); /* Second bounce */
	}
	75% {
		transform: translateY(0); /* Back down */
	}
	85% {
		transform: translateY(-15px); /* Third bounce, smaller */
	}
	95% {
		transform: translateY(0); /* Back down */
	}
	100% {
		transform: translateY(-10px); /* Fourth and smallest bounce */
	}
}

.bounce__box {
	animation: bounce 1s ease;
}

//read more
.ream_more {
	position: relative;
	height: 100%;
	.text {
		width: 100%;
	}

	.read-or-hide {
		color: currentColor;
		cursor: pointer;
		font-weight: 700;
		width: auto !important;
		padding: 0;
		border-radius: 5px;
		text-align: center;
		margin-left: 3px;
	}
}

.swiper-pagination-bullet {
	background-color: $primary !important;
	height: 15px !important;
	width: 15px !important;
	margin-bottom: 15px !important;
}

.product-slider {
	.swiper-button-next {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		width: 40px;
		height: 40px;
		background-color: #ffffff;
		border: 1px solid getColor(grey300);
		border-radius: 50%;
		z-index: 1;
		cursor: pointer;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 45%;
			transform: translate(-50%, -50%) rotate(45deg);
			width: 10px;
			height: 10px;
			border-top: 2px solid getColor(grey900);
			border-right: 2px solid getColor(grey900);
		}
	}

	.swiper-button-prev {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		background-color: #ffffff;
		width: 40px;
		height: 40px;
		border: 1px solid getColor(grey300);
		background-color: #ffffff;
		border-radius: 50%;
		z-index: 1;
		cursor: pointer;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 55%;
			transform: translate(-50%, -50%) rotate(-135deg);
			width: 10px;
			height: 10px;
			border-top: 2px solid getColor(grey900);
			border-right: 2px solid getColor(grey900);
		}
	}
	// swiper-button-disabled
	.swiper-button-disabled {
		display: none;
	}
}
.product-slider-category {
	.swiper-button-next {
		position: absolute;
		top: 35%;
		right: 0;
		transform: translateY(-50%);
		width: 40px;
		height: 40px;
		background-color: #ffffff;
		border: 1px solid getColor(grey300);
		border-radius: 50%;
		z-index: 1;
		cursor: pointer;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 45%;
			transform: translate(-50%, -50%) rotate(45deg);
			width: 10px;
			height: 10px;
			border-top: 2px solid getColor(grey900);
			border-right: 2px solid getColor(grey900);
		}
	}

	.swiper-button-prev {
		position: absolute;
		top: 35%;
		left: 0;
		transform: translateY(-50%);
		background-color: #ffffff;
		width: 40px;
		height: 40px;
		border: 1px solid getColor(grey300);
		background-color: #ffffff;
		border-radius: 50%;
		z-index: 1;
		cursor: pointer;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 55%;
			transform: translate(-50%, -50%) rotate(-135deg);
			width: 10px;
			height: 10px;
			border-top: 2px solid getColor(grey900);
			border-right: 2px solid getColor(grey900);
		}
	}
	// swiper-button-disabled
	.swiper-button-disabled {
		display: none;
	}
}
.product-attr-slider {
	.swiper-button-next {
		position: absolute;
		top: 50%;
		right: 0;
		transform: translateY(-50%);
		width: 40px;
		height: 40px;
		background-color: $primary;
		border-radius: 50%;
		z-index: 1;
		cursor: pointer;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 45%;
			transform: translate(-50%, -50%) rotate(45deg);
			width: 10px;
			height: 10px;
			border-top: 2px solid #fff;
			border-right: 2px solid #fff;
		}
	}

	.swiper-button-prev {
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		width: 40px;
		height: 40px;
		background-color: $primary;
		border-radius: 50%;
		z-index: 1;
		cursor: pointer;
		&::after {
			content: '';
			position: absolute;
			top: 50%;
			left: 55%;
			transform: translate(-50%, -50%) rotate(-135deg);
			width: 10px;
			height: 10px;
			border-top: 2px solid #fff;
			border-right: 2px solid #fff;
		}
	}
	// swiper-button-disabled
	.swiper-button-disabled {
		display: none;
	}
}
.toastMain {
	.commonParent {
		max-width: 300px;
		color: #f2f2f2;

		.toast_box {
			display: flex;
			align-items: center;
		}
	}
	.successParent {
		background-color: #66c27d;
	}
	.errorParent {
		background-color: #f05b5b;
	}
}

.swiper {
	margin-left: auto;
	margin-right: auto;
	// position: relative;
	overflow: hidden;
	overflow: clip;
	list-style: none;
	padding: 0;
	/* Fix of Webkit flickering */
	z-index: 0;
	display: block;
}
.swiper-vertical > .swiper-wrapper {
	flex-direction: column;
}
.swiper-wrapper {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 0;
	display: flex;
	transition-property: transform;
	transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
	box-sizing: content-box;
}
.swiper-android .swiper-slide,
.swiper-ios .swiper-slide,
.swiper-wrapper {
	transform: translate3d(0px, 0, 0);
}
.swiper-horizontal {
	touch-action: pan-y;
}
.swiper-vertical {
	touch-action: pan-x;
}
.swiper-slide {
	flex-shrink: 0;
	width: 100%;
	height: 100%;
	position: relative;
	transition-property: transform;
	display: block;
}
.swiper-slide-invisible-blank {
	visibility: hidden;
}
/* Auto Height */
.swiper-autoheight,
.swiper-autoheight .swiper-slide {
	height: auto;
}
.swiper-autoheight .swiper-wrapper {
	align-items: flex-start;
	transition-property: transform, height;
}
.swiper-backface-hidden .swiper-slide {
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}
/* 3D Effects */
.swiper-3d.swiper-css-mode .swiper-wrapper {
	perspective: 1200px;
}
.swiper-3d .swiper-wrapper {
	transform-style: preserve-3d;
}
.swiper-3d {
	perspective: 1200px;
}
.swiper-3d .swiper-slide,
.swiper-3d .swiper-cube-shadow {
	transform-style: preserve-3d;
}
/* CSS Mode */
.swiper-css-mode > .swiper-wrapper {
	overflow: auto;
	scrollbar-width: none;
	/* For Firefox */
	-ms-overflow-style: none;
	/* For Internet Explorer and Edge */
}
.swiper-css-mode > .swiper-wrapper::-webkit-scrollbar {
	display: none;
}
.swiper-css-mode > .swiper-wrapper > .swiper-slide {
	scroll-snap-align: start start;
}
.swiper-css-mode.swiper-horizontal > .swiper-wrapper {
	scroll-snap-type: x mandatory;
}
.swiper-css-mode.swiper-vertical > .swiper-wrapper {
	scroll-snap-type: y mandatory;
}
.swiper-css-mode.swiper-free-mode > .swiper-wrapper {
	scroll-snap-type: none;
}
.swiper-css-mode.swiper-free-mode > .swiper-wrapper > .swiper-slide {
	scroll-snap-align: none;
}
.swiper-css-mode.swiper-centered > .swiper-wrapper::before {
	content: '';
	flex-shrink: 0;
	order: 9999;
}
.swiper-css-mode.swiper-centered > .swiper-wrapper > .swiper-slide {
	scroll-snap-align: center center;
	scroll-snap-stop: always;
}
.swiper-css-mode.swiper-centered.swiper-horizontal > .swiper-wrapper > .swiper-slide:first-child {
	margin-inline-start: var(--swiper-centered-offset-before);
}
.swiper-css-mode.swiper-centered.swiper-horizontal > .swiper-wrapper::before {
	height: 100%;
	min-height: 1px;
	width: var(--swiper-centered-offset-after);
}
.swiper-css-mode.swiper-centered.swiper-vertical > .swiper-wrapper > .swiper-slide:first-child {
	margin-block-start: var(--swiper-centered-offset-before);
}
.swiper-css-mode.swiper-centered.swiper-vertical > .swiper-wrapper::before {
	width: 100%;
	min-width: 1px;
	height: var(--swiper-centered-offset-after);
}
/* Slide styles start */
/* 3D Shadows */
.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top,
.swiper-3d .swiper-slide-shadow-bottom,
.swiper-3d .swiper-slide-shadow,
.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-top,
.swiper-3d .swiper-slide-shadow-bottom {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	z-index: 10;
}
.swiper-3d .swiper-slide-shadow {
	background: rgba(0, 0, 0, 0.15);
}
.swiper-3d .swiper-slide-shadow-left {
	background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-3d .swiper-slide-shadow-right {
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-3d .swiper-slide-shadow-top {
	background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-3d .swiper-slide-shadow-bottom {
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-lazy-preloader {
	width: 42px;
	height: 42px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -21px;
	margin-top: -21px;
	z-index: 10;
	transform-origin: 50%;
	box-sizing: border-box;
	// border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
	border-radius: 50%;
	border-top-color: transparent;
}
.swiper:not(.swiper-watch-progress) .swiper-lazy-preloader,
.swiper-watch-progress .swiper-slide-visible .swiper-lazy-preloader {
	animation: swiper-preloader-spin 1s infinite linear;
}
.swiper-lazy-preloader-white {
	--swiper-preloader-color: #fff;
}
.swiper-lazy-preloader-black {
	--swiper-preloader-color: #000;
}
@keyframes swiper-preloader-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.banner-slider {
	.swiper-slide.swiper-slide-visible {
		margin-right: 0px !important;
	}
}

/* Slide styles end */

// @media screen
// without bigscreeen
@media screen and (max-width: 1024px) {
	.single-carousel-image {
		height: 300px !important;
		width: 100% !important;
	}
}

@media screen and (max-width: 768px) {
	.single-carousel-image {
		height: 250px !important;
		width: 100% !important;
	}
}

@media screen and (max-width: 576px) {
	.single-carousel-image {
		height: 250px !important;
		width: 100% !important;
	}
}

@media screen and (max-width: 480px) {
	.single-carousel-image {
		height: 250px !important;
		width: 100% !important;
	}
}

@media screen and (max-width: 320px) {
	.single-carousel-image {
		height: 250px !important;
		width: 100% !important;
	}
}

// with bigscreen
@media screen and (min-width: 1024px) {
	.single-carousel-image {
		height: 500px !important;
		width: 100% !important;
	}
}

//desscription
.product_details_body {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.infinite-scroll-component {
	overflow: unset !important;
}

.custom-page-content {
	a {
		color: $primary;
	}
}

.blog_featured_slider {
	.swiper-pagination {
		text-align: start !important;
		margin-bottom: 20px;
		padding-left: 20px;
	}
}

.job-header__description {
	p {
		background-color: transparent !important;
	}
}

// loading spinner
#nprogress {
	position: fixed !important;
	top: 0 !important;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999; /* High z-index to make sure it's above other content */
	height: 100vh;
	width: 100vw;
	pointer-events: all !important;
}
#nprogress .peg {
	display: block;
	position: absolute;
	right: 0px;
	width: 100px;
	height: 100%;
	box-shadow:
		0 0 10px #29d,
		0 0 5px #29d;
	opacity: 1;

	-webkit-transform: rotate(3deg) translate(0px, -4px);
	-ms-transform: rotate(3deg) translate(0px, -4px);
	transform: rotate(3deg) translate(0px, -4px);
}

#nprogress .spinner-icon {
	width: 80px !important;
	height: 80px !important;
	box-sizing: border-box;

	border: solid 6px transparent !important;
	border-top-color: #0e7673 !important;
	border-left-color: #0e7673 !important;
	border-radius: 50%;

	-webkit-animation: nprogress-spinner 700ms linear infinite !important;
	animation: nprogress-spinner 700ms linear infinite !important;
}

.nprogress-custom-parent {
	overflow: hidden;
	position: relative;
}
#nprogress .bar {
	background: #0e7673 !important;
}
.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
	position: absolute;
	height: 100% !important;
}

@-webkit-keyframes nprogress-spinner {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes nprogress-spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.requested_products {
	// add border bottm  expet last child immediare div
	& > div:not(:last-child) {
		border-bottom: 1px solid #e5e5e5;
	}
}

@media (max-width: 992px) {
	.container-fluid {
		margin-top: 60px !important;
	}
}

.hidden {
	display: none;
}
.mt-70 {
	margin-top: 70px !important;
}
