@import '@/services/scss/variables.scss';

.prescription_slider {
	padding-top: 20px;
	opacity: 1;
	transform: translateX(0);
	transition: transform 0.5s ease-in-out;

	button {
		background-color: getColor(white);
		border: 1px solid getColor(grey300);
		border-radius: 12px;
		padding: 9px 9px;
		margin-right: 10px;
		// @include lastChildMargin;
		font-size: 12px;
		cursor: pointer;
	}
	//disabled button
	button:disabled {
		background-color: #ced4da; /* Gray background color for disabled button */
		color: #6c757d; /* Gray text color for disabled button */
		cursor: not-allowed;
		&:hover {
			background-color: #ced4da; /* Gray background color for disabled button */
			color: #6c757d; /* Gray text color for disabled button */
		}
	}
	.next {
		background-color: getColor(primary);
		color: getColor(white);
		border-radius: 100%;
		width: 40px;
		height: 40px;
		z-index: 1;
		//hover
		&:hover {
			background-color: getColor(primary400);
			color: getColor(white);
		}
	}
	.previous {
		background-color: getColor(primary);
		color: getColor(white);
		border-radius: 100%;
		width: 40px;
		height: 40px;
		z-index: 1;
		//hover
		&:hover {
			background-color: getColor(primary400);
			color: getColor(white);
		}
	}
}
