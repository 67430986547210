$offerSliderColors: color1, color2, color3, color4, color5, color6;
$colorss: (
	color1: #50c756,
	color2: #fe964a,
	color3: #ee7676,
	color4: #9873fd,
	color5: #79ebe4,
	color6: #648f09
);
@function get-next-color($current-index) {
	$total-colors: length($offerSliderColors);
	$next-index: $current-index % $total-colors + 1;
	@return map-get($map: $colorss, $key: nth($offerSliderColors, $next-index));
}
@function generate-gradient-colors($base-color) {
	$color1: lighten($base-color, 50%); // Lightens the base color to get a variant similar to #FFF0F0
	$color2: mix($base-color, $color1, 50%); // Mixes the base color with #FFC5C5 to get a middle ground color

	@return ($color1, $color2);
}
@function generate-svg-colors($base-color) {
	$color1: lighten($base-color, 30%); // Lightens the base color to get a variant similar to #FFF0F0
	$color2: $base-color; // Mixes the base color with #FFC5C5 to get a middle ground color

	@return ($color1, $color2);
}
$max-slides: 20;

.offers_continer {
	.slider_wrap {
		margin-right: 20px;
		margin-right: 20px;
		@for $i from 1 through $max-slides {
			&:nth-child(#{$i}) {
				.offer {
					background-image: linear-gradient(
						to bottom right,
						nth(generate-gradient-colors(get-next-color($i - 1)), 1),
						nth(generate-gradient-colors(get-next-color($i - 1)), 2)
					);
					display: flex;
					align-items: center;
					gap: 10px;
					padding: 10px;
					border-radius: 6px;
					margin-right: 10px;
					font-size: 18px;
					color: get-next-color($i - 1);

					.offer_icon {
						margin-right: 5px;
						height: 57px;
						width: 57px;
						.stop1 {
							stop-color: nth(generate-svg-colors(get-next-color($i - 1)), 1);
						}
						.stop2 {
							stop-color: nth(generate-svg-colors(get-next-color($i - 1)), 2);
						}
					}

					.shape_top {
						position: absolute;
						top: -10px;
						width: 18px;
						height: 9px;
						background-color: #ffffff;
						border-radius: 0 0 19px 19px;
						left: -10px;
					}
					.shape_bottom {
						position: absolute;
						bottom: -10px;
						width: 20px;
						height: 9px;
						background-color: #ffffff;
						border-radius: 19px 19px 0 0;
						left: -10px;
					}

					.offer_price {
						color: get-next-color($i - 1);
						font-size: 20px;
						font-style: normal;
						font-weight: 700;
						line-height: 150%; /* 30px */
					}
					.heading {
						color: get-next-color($i - 1);
						font-size: 16px;
						font-style: normal;
						font-weight: 700;
						line-height: 160%; /* 25.6px */
					}
				}
			}
		}
		.colors {
			color: $primary;
			border: 2px dashed $primary;
		}
	}
}
