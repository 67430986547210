.show_hamburger{
    display: none;
}


@media (min-width: 576px) {
    .show_hamburger {
        display: block;
    }
}
@media (min-width: 768px) {
    .show_hamburger {
        display: block;
    }
}
@media (min-width: 800px) {
    .show_hamburger {
        display: block;
    }
}
@media (min-width: 1024px) {
    .show_hamburger {
        display: block;
    }
}

@media (min-width: 1100px) {
    .show_hamburger {
        display: block;
    
    }
}

@media (min-width: 1200px) {
    .show_hamburger {
        display: block;
    }
}
@media (min-width: 1280px) {
    .show_hamburger {
        display: block;
    }
}
@media (min-width: 1300px) {
    .show_hamburger {
        display: none;
    }
}
@media (min-width: 1400px) {
    .show_hamburger {
        display: none;
    }
}
@media (min-width: 1600px) {
    .show_hamburger {
        display: none;
    }
}