@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';
/* styles/switch.scss */
.switch {
	position: relative;
	display: inline-block;
	width: 44px; /* Updated width */
	height: 24px; /* Updated height */
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.switch .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: 0.4s;
	border-radius: 24px; /* Half of the height to make it a circle */
}

.switch .slider:before {
	position: absolute;
	content: '';
	height: 20px; /* Updated height */
	width: 20px; /* Updated width */
	left: 2px; /* Adjust the position */
	bottom: 2px; /* Adjust the position */
	background-color: white;
	transition: 0.4s;
	border-radius: 50%;
}

.switch input:checked + .slider {
	background-color: getColor(primary);
}

.switch input:focus + .slider {
	box-shadow: 0 0 1px getColor(primary);
}

.switch input:checked + .slider:before {
	transform: translateX(20px); /* Updated width */
}

.switch .left_side {
	left: 4px; /* Adjust the position */
}

.switch .right_side {
	right: 4px; /* Adjust the position */
}
