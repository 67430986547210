@import '@/services/scss/variables.scss';
@keyframes slideInFromRight {
	from {
		transform: translate(-50%, -100%);
		opacity: 0;
	}
	to {
		transform: translate(-50%, -50%);
		opacity: 1;
	}
}
.confirmModal {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px 40px;
	background-color: #fff;

	.icon {
		font-size: 50px;
		height: 120px;
		width: 120px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		&.warning {
			color: getColor(warning);
			background-color: getColor(secondary100);
		}
		&.success {
			color: getColor(white);
			background-color: #d3e4df;
			svg {
				color: getColor(primary);
			}
		}
		&.error {
			color: getColor(white);
			background-color: #ebdddd;
			svg {
				color: getColor(error);
			}
		}
		&.info {
			color: getColor(white);
			background-color: #d2dcec;
			svg {
				color: getColor(blue);
			}
		}
		&.orderSuccess {
			height: 200px;
			width: 200px;
		}
	}

	.content {
		margin-top: 20px;
		text-align: center;

		.title {
			font-size: 20px;
			font-weight: 700;
			margin-bottom: 10px;
			color: getColor(grey900);
		}

		.message {
			font-size: 16px;
			color: getColor(grey700);
		}
	}
	.footer {
		margin-top: 40px;
		display: flex;
		gap: 10px;
		width: 100%;

		.button {
			width: 100%;
			height: 40px;
		}
	}
}

.toastModal {
	position: fixed;
	overflow: hidden;
	top: 10%;
	left: 60%;
	transform: translate(-50%, -50%);
	z-index: 9999;
	display: flex;
	padding: 16px;
	border-radius: 8px;
	width: 624px;
	box-shadow:
		0 3px 10px rgba(0, 0, 0, 0.1),
		0 3px 3px rgba(0, 0, 0, 0.05);
	animation: slideInFromRight 0.5s forwards;
	transition: all 230ms cubic-bezier(0.21, 1.02, 0.73, 1) 0s;
	.icon {
		height: 24px;
		width: 24px;
		color: getColor(primary);
	}
	.close {
		position: absolute;
		top: 40%;
		right: 16px;
		cursor: pointer;
	}
	&.success {
		color: getColor(white);
		background-color: #ecfbf5;
	}
	&.warning {
		color: getColor(white);
		background-color: #fff5e6;
	}
	&.error {
		color: getColor(white);
		background-color: getColor(error_bg);
		border: 1px solid getColor(error_bg);
		.icon {
			height: 24px;
			width: 24px;
			fill: getColor(error);
			color: getColor(error);
		}
		.close {
			position: absolute;
			top: 40%;
			color: #fff;
			right: 16px;
			cursor: pointer;
		}
	}
}

@keyframes linearProgress {
	from {
		width: 0%;
	}
	to {
		width: 100%;
	}
}
.linearProgress {
	height: 1px;
	background-color: getColor(primary);
	animation: linearProgress 5s forwards;
	position: absolute; // Absolute positioning within the fixed container
	top: 0px; // Position at the top
	left: 0; // Start from the leftmost side
	right: 0; // Stretch it to the rightmost side
	border-radius: 100px 100px 0px 0px;

	&.error {
		background-color: getColor(error);
	}

	&.warning {
		background-color: getColor(warning);
	}
}
