.header {
	position: fixed;
	top: 0;
	width: 100%; /* Ensure it spans the entire width of the viewport */
	z-index: 1;
	height: 70px; /* Adjust based on your needs */
	// padding-inline: 30px;
	transition: top 0.3s;
	background-color: #fff;
	border-bottom: 1px solid color(grey200);
	z-index: 1200;
	.header_top {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: center;	
		width: 100%;

		.header_top_left {
			-webkit-box-align: center;
			align-items: center;
			display: flex;
			width: auto;
			padding-inline-start: 30px;
			-left: 30x;
			.header_logo {
				margin: 9px 0;
				img {
					width: 110px;
					height: 100%;
					object-fit: cover;
					margin-top: 5px;
				}
			}
			.top__left__delivery_warp {
				display: none;
				cursor: pointer;
				border-radius: 10px;
				padding: 5px 0px;
				padding-right: 30px;
				&:hover {
					background-color: getColor(primary_bg);
					svg {
						fill: getColor(primary);
					}
				}

				.top__left__delivery {
					display: flex;
					align-items: center;
					.top__left__delivery__icon {
						margin-right: 2px;
						@include color(grey900);
						margin-top: 17px;
					}
					.top__left__delivery__content {
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;
						gap: 5px;
					}
					.top__left__delivery__location {
						font-size: 14px;
						font-weight: 600;
						color: getColor(grey900);
						text-wrap: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						max-width: 170px;
					}
					.top__left__delivery__icon_arrow {
						margin-top: 25px;
					}
				}
			}
		}
		.header_top_middle {
			-webkit-box-align: center;
			display: flex;
			justify-content: space-between;
			flex: 1 1 0%;
			margin-inline-end: 20px;
		}
		.header_top_right {
			display: flex;
			-webkit-box-pack: center;
			justify-content: center;
			top: 0px;
			right: 0px;
			padding: 0px;
			padding-inline-end: 30px;
			gap: 5px;
			position: relative;
			align-items: center;
			cursor: pointer;

			.top__right__user {
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				padding: 5px 10px;
				&:hover {
					background-color: getColor(primary_bg);
					border-radius: 10px;
					padding: 5px 10px;
				}
				.top__right__user__icon {
					margin-right: 10px;
				}
			}
			.top__right__cart {
				align-items: center;
				justify-content: center;
				position: relative;

				.top__right__cart__icon {
					margin-right: 10px;
				}
				svg {
					fill: color(grey700);
				}
				.top__right__cart__count {
					position: absolute;
					top: -10px;
					right: -10px;
					background-color: getColor(error);
					color: white;
					border-radius: 50%;
					width: 20px;
					height: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 12px;
					font-weight: 500;
				}
			}
		}
	}
}

.header-navbar {
	display: none;
	z-index: 1000;
	position: fixed;
	top: 0;
	width: 100%;
	transition: top 0.3s;
	min-height: 50px;

	.header_nav {
		margin-top: 70px;
		background-color: #fff;
		box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
		border-bottom: #000;
		margin-top: 70px;
		display: flex;
		width: 100%;
		padding-inline: 30px;
		justify-content: center;
		align-items: center;
		.nav_category {
			position: absolute;
			left: 15px;
			align-items: center;
			height: fit-content;
			justify-content: center;
			align-items: center;
			text-wrap: nowrap;
			border-radius: 10px;
			display: none;
			padding: 5px 10px;
			gap: 5px;
			color: getColor(primary);
			font-weight: 400;
		}
		.navbar__items {
			display: flex;
			gap: 15px;

			.nav_item {
				height: 50px;
				font-size: 16px;
				color: getColor(grey600);
				text-decoration: none;
				align-items: center;
				display: flex;
				border-bottom: 3px solid white;
				text-wrap: nowrap;
				//ellipsis
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				transition:
					transform 0.3s,
					box-shadow 0.3s,
					background-color 0.3s,
					color 0.3s; // Added transition for transform, box-shadow, background-color and color
				font-weight: 400;
				cursor: pointer;

				&.nav__item__active {
					border-bottom: 3px solid getColor(primary);
					color: getColor(primary);
				}
				&:hover {
					border-bottom: 3px solid getColor(primary);
					color: getColor(primary);
					.nav__item__megamenu {
						opacity: 1;
						visibility: visible;
						z-index: 1000;
					}
				}
			}

			.nav__item__megamenu {
				position: absolute;
				top: 100%;
				background-color: #fff;
				left: 0;
				min-height: 300px;
				min-width: 1200px;
				z-index: 1000;
				box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
				display: block;
				opacity: 0;
				visibility: hidden;
				transition:
					opacity 0.3s,
					visibility 0.3s;
				left: 25%;
				height: 500px;
				overflow: scroll;
				&::-webkit-scrollbar {
					width: 5px;
					height: 5px;
				}

				&::-webkit-scrollbar-thumb {
					background-color: $primary; /* Color of the scroll thumb */
					border-radius: 20px; /* Roundness of the scroll thumb */
				}

				&.active {
					opacity: 1;
					visibility: visible; // Set to visible on hover
					z-index: 1000;
				}

				.masonry_grid {
					> div:nth-child(even) {
						background-color: #f7fafc;
					}
					> div {
						padding: 10px;
					}
					.grid_item {
						line-height: 23px !important;
						font-weight: 300;
						font-size: 14px;
						margin-bottom: 10px;

						.grid_item__title {
							font-weight: 500;
							font-size: 16px;
							text-wrap: wrap;
							color: getColor(primary);
							margin-bottom: 10px;
						}
						.grid_item__list {
							display: flex;
							flex-direction: column;
							.grid_item__list__item {
								font-size: 14px;
								text-overflow: ellipsis;
								overflow: hidden;
								color: $dark;
								margin-bottom: 1px;
								&:hover {
									color: $dark;
									font-weight: 500;
								}
							}
						}
					}
				}
			}
		}
		.nav-call-for-order {
			position: absolute;
			right: 30px;
			font-size: 14px;
			font-weight: 400;
			@include color(error);
			align-items: center;
			height: fit-content;
			justify-content: center;
			align-items: center;
			text-wrap: nowrap;
			border-radius: 10px;
			background-color: rgb($error, 0.1);
			display: none;

			padding: 5px 10px;
			gap: 5px;
			z-index: 2;
			cursor: pointer;
			//hover
			&:hover {
				background-color: rgb($error, 0.2);
			}
		}
	}
}

.nav_hide {
	top: -100px; // Adjust based on your navbar's height
}

.sidebar_wrap {
	display: none;
	transition: transform 0.3s;
	width: 325px;
	gap: 40px;
	background-color: white;
	border-right: 1px solid color(grey300);
	position: sticky;
	top: 65px;
	height: calc(100vh - 65px);
	min-width: 325px;
	.sidebar {
		max-height: calc(100vh - 130px);
		overflow-y: auto;
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: getColor(grey400); /* Color of the scroll thumb */
			border-radius: 20px; /* Roundness of the scroll thumb */
		}
		.side_nav__category {
			padding-top: 18px;
			height: fit-content;
			align-items: center;
			text-wrap: nowrap;
			border-radius: 10px;
			display: flex;
			gap: 5px;
			color: getColor(primary);
			font-weight: 400;
			padding-inline-start: 26px;
			padding-bottom: 10px;
		}
	}
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
	.header-navbar {
		display: none;
		
	}
	.show_search_bar{
		display: flex;
		justify-content: center;
		align-items: center;
	

	}
	.header_top_middle{
		display: none;
	
	}
}


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
	.header-navbar {
		display: none;
	}
	.show_search_bar{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.header_top_middle{
		display: none;
	
	}
}
@media only screen and (min-width: 800px) {
	.header-navbar {
		display: none;
	}
	.show_search_bar{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.header_top_middle{
		display: none;
	
	}
}
@media only screen and (min-width: 900px) {
	.header-navbar {
		display: none;
	}
	.show_search_bar{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.header_top_middle{
		display: none;
	
	}
}

@media only screen and (min-width: 1024px) {
	.header-navbar {
		display: flex;
	}
	.navbar_text_size{
		font-size: 9px;
	}
	.show_search_bar{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.header_top_middle{
		display: none;
	
	}
}
@media only screen and (min-width: 1200px) {
	.header-navbar {
		display: flex;
	}
	.navbar_text_size{
		font-size: 10px;
	}
	.show_search_bar{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.header_top_middle{
		display: none;
	
	}
	
	
}
@media only screen and (min-width: 1296px) {
	.navbar_text_size{
		font-size: 11px;
	}	
}
@media only screen and (min-width: 1300px) {
	.header-navbar {
		display: flex;
	}
	.navbar_text_size{
		font-size: 12px;
	}
	.show_search_bar{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.header_top_middle{
		display: none;
	
	}
	
	
}

@media only screen and (min-width: 1400px) {
	.header-navbar {
		display: flex;
	}
	.navbar_text_size{
		font-size: 14px;
	}
	.show_search_bar{
		display: none;
	}
	.header_top_middle{
		display: flex;
	
	}
}
@media only screen and (min-width: 1800px) {

	.navbar_text_size{
		font-size: 15px;
	}

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
	.sidebar_wrap {
		display: block;
	}

	.header {
		.header_top {
			.header_top_left {
				.top__left__delivery_warp {
					display: flex;
				}
			}
		}
	}
}


@media only screen and (min-width: 1820px) {
	.header-navbar {
		.header_nav {
			.nav-call-for-order {
				display: flex;
			}
			.nav_category {
				display: flex;
			}
		}
	}

	.navbar_text_size{
		font-size: 15px;
	}
}
