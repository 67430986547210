@include text-transform-utilities;

//base Global
.d-flex {
	display: flex !important;
}

@for $multiplier from 0 through $max-multiplier {
	.mt-#{$multiplier} {
		margin-top: $default-margin * $multiplier;
	}

	.mb-#{$multiplier} {
		margin-bottom: $default-margin * $multiplier;
	}
	.mr-#{$multiplier} {
		margin-right: $default-margin * $multiplier;
	}
	.ml-#{$multiplier} {
		margin-left: $default-margin * $multiplier;
	}

	.mx-#{$multiplier} {
		margin-left: $default-margin * $multiplier;
		margin-right: $default-margin * $multiplier;
	}

	.my-#{$multiplier} {
		margin-top: $default-margin * $multiplier;
		margin-bottom: $default-margin * $multiplier;
	}
	.m-#{$multiplier} {
		margin: $default-margin * $multiplier;
	}
	.pb-#{$multiplier} {
		padding-bottom: $default-margin * $multiplier !important;
	}

	.pb-#{$multiplier} {
		padding-bottom: $default-margin * $multiplier !important;
	}
	.pt-#{$multiplier} {
		padding-top: $default-margin * $multiplier !important;
	}
	.pl-#{$multiplier} {
		padding-left: $default-margin * $multiplier !important;
	}
	.pr-#{$multiplier} {
		padding-right: $default-margin * $multiplier !important;
	}
	.px-#{$multiplier} {
		padding-left: $default-margin * $multiplier !important;
		padding-right: $default-margin * $multiplier !important;
	}
	.p-#{$multiplier} {
		padding: $default-margin * $multiplier !important;
	}

	.py-#{$multiplier} {
		padding-top: $default-margin * $multiplier !important;
		padding-bottom: $default-margin * $multiplier !important;
	}
	.text-#{$multiplier} {
		font-size: #{$multiplier}px !important;
	}

	.z-#{$multiplier} {
		z-index: #{$multiplier} !important;
	}
	.line-height-#{multiplier} {
		line-height: $multiplier !important;
	}

	.gap-#{$multiplier} {
		gap: #{$multiplier}px !important;
	}
}

.w-full {
	width: 100% !important;
}
.h-full {
	height: 100% !important;
}
@for $px from 1 through 50 {
	.w-#{$px} {
		width: #{$px}px !important;
	}
	.h-#{$px} {
		height: #{$px}px !important;
	}
}
@each $color-name, $color-value in $colors {
	.text-#{$color-name} {
		color: $color-value;
	}
	.bg-#{$color-name} {
		background-color: $color-value;
	}
	// hover-text-primary
	.hover-text-#{$color-name} {
		&:hover {
			color: $color-value !important;
			svg {
				fill: $color-value;
			}
			span {
				color: $color-value;
			}
		}
	}
	.hover-bg-#{$color-name} {
		&:hover {
			background-color: $color-value !important;
		}
	}
	.hover-border-#{$color-name} {
		&:hover {
			border: 1px solid $color-value !important;
		}
	}
}
@for $width from 1 through 3 {
	@each $color-name, $color-value in $colors {
		.border#{$width}-#{$color-name} {
			border: #{$width}px solid $color-value !important;
		}
		.border-#{$width}-#{$color-name} {
			border: #{$width}px solid $color-value !important;
		}
		.border-t#{$width}-#{$color-name} {
			border-top: #{$width}px solid $color-value !important;
		}
		.border-b#{$width}-#{$color-name} {
			border-bottom: #{$width}px solid $color-value !important;
		}
		.border-l#{$width}-#{$color-name} {
			border-left: #{$width}px solid $color-value !important;
		}
		.border-r#{$width}-#{$color-name} {
			border-right: #{$width}px solid $color-value !important;
		}
	}
}
.rounded-full {
	border-radius: 100% !important;
}

.rounded-xl {
	border-radius: 1rem !important;
}

.rounded-2xl {
	border-radius: 2rem !important;
}

// Dynamic border-radius based on pixel value
@for $px from 1 through 10 {
	.rounded-#{$px} {
		border-radius: #{$px}px !important;
	}
	.rounded-start-#{$px} {
		border-top-left-radius: #{$px}px !important;
		border-bottom-left-radius: #{$px}px !important;
	}
	.rounded-end-#{$px} {
		border-top-right-radius: #{$px}px !important;
		border-bottom-right-radius: #{$px}px !important;
	}
}
$cursor-values: auto, default, pointer, grab, help, wait;

@each $cursor in $cursor-values {
	.cursor-#{$cursor} {
		cursor: $cursor;
	}
}

$justify-content-values: center, flex-start, flex-end, space-between, space-around, space-evenly;
@each $justify in $justify-content-values {
	.justify-#{$justify} {
		justify-content: $justify !important;
	}
}
// align-self
$align-self-values: center, flex-start, flex-end, baseline, stretch;
@each $align in $align-self-values {
	.self-#{$align} {
		align-self: $align !important;
	}
}

$font-weight-values: 100, 200, 300, 400, 500, 600, 700, 800, 900;
@each $weight in $font-weight-values {
	.fw-#{$weight} {
		font-weight: $weight;
	}
}
$align-items-values: center, flex-start, flex-end, baseline, stretch;
@each $align in $align-items-values {
	.items-#{$align} {
		align-items: $align !important;
	}
}
$text-align-values: left, right, center, justify;
@each $align in $text-align-values {
	.text-#{$align} {
		text-align: $align !important;
	}
}
$flex-directions: row, column, row-reverse, column-reverse;
@each $direction in $flex-directions {
	.flex-#{$direction} {
		flex-direction: $direction !important;
	}
}

$flex-wrap-values: nowrap, wrap, wrap-reverse;
@each $wrap in $flex-wrap-values {
	.flex-wrap-#{$wrap} {
		flex-wrap: $wrap;
	}
}
$flex-wrap-values: nowrap, wrap, wrap-reverse;
@each $wrap in $flex-wrap-values {
	.flex-#{$wrap} {
		flex-wrap: $wrap;
	}
}

$text-wrap-values: normal, nowrap, pre, pre-wrap, pre-line;
@each $wrap in $text-wrap-values {
	.text-wrap-#{$wrap} {
		white-space: $wrap;
	}
}

$position-values: relative, absolute, fixed, sticky;
@each $position in $position-values {
	.position-#{$position} {
		position: $position;
	}
}

.mx-auto {
	margin-left: auto !important;
	margin-right: auto !important;
}

// grid grid-cols-2
@for $i from 1 through 12 {
	.grid-cols-#{$i} {
		grid-template-columns: repeat(#{$i}, minmax(0, 1fr));
	}
}

.grid {
	display: grid;
}
//  bg-opacity-10
@for $i from 0 through 100 {
	.bg-opacity-#{$i} {
		background-color: rgba(0, 0, 0, $i / 100);
	}
}

.wrap {
	text-wrap: wrap !important;
}
