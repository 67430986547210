$color_1: #666;
$color_2: #999;
$color_3: #777;
$color_4: color(primary_bg);
$background-color_1: color(primary);
$background-color_2: #e5e5e5;
$background-color_3: #fff;
$background-color_4: color(primary_bg);

@keyframes rcMenuOpenSlideUpIn {
	0% {
		opacity: 0;
		transform-origin: 0% 0%;
		transform: scaleY(0);
	}
	100% {
		opacity: 1;
		transform-origin: 0% 0%;
		transform: scaleY(1);
	}
}
@keyframes rcMenuOpenSlideUpOut {
	0% {
		opacity: 1;
		transform-origin: 0% 0%;
		transform: scaleY(1);
	}
	100% {
		opacity: 0;
		transform-origin: 0% 0%;
		transform: scaleY(0);
	}
}
@keyframes rcMenuOpenZoomIn {
	0% {
		opacity: 0;
		transform: scale(0, 0);
	}
	100% {
		opacity: 1;
		transform: scale(1, 1);
	}
}
@keyframes rcMenuOpenZoomOut {
	0% {
		transform: scale(1, 1);
	}
	100% {
		opacity: 0;
		transform: scale(0, 0);
	}
}
.arogga-account {
	outline: none;
	margin-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	list-style: none;
	margin-top: 0;

	border-radius: 3px;
	color: $color_1;
	ul {
		margin-block-start: 0;
	}
	> li.arogga-account-submenu {
		padding: 0;
		border-bottom: 1px solid color(grey300);
	}
	> li.arogga-account-submenu.arogga-account-submenu-open {
		padding: 0;
		border-bottom: none;
	}
	.arogga-account-submenu-selected .arogga-account-submenu-title {
		svg {
			width: 24px;
			height: 24px;
			fill: getColor(white);
		}
	}
	.arogga-account-submenu-title {
		svg {
			width: 24px;
			height: 24px;
			fill: getColor(grey600);
		}
	}

	.arogga-account-sub .arogga-account-item-selected {
		background-color: $background-color_4;
		color: getColor(primary);
		font-size: 16px;
		svg {
			color: getColor(primary);
			fill: getColor(primary);
		}
	}

	.arogga-account-item {
		padding-block: 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 18px;
		border-bottom: 1px solid color(grey300);
		svg {
			width: 24px;
			height: 24px;
			color: #d9d9d9;
			fill: getColor(grey600);
		}
	}
	.arogga-account-sub .arogga-account-item {
		padding-left: 62px !important;
	}
}

.arogga-account-hidden {
	display: none;
}
.arogga-account-submenu-hidden {
	display: none;
}
.arogga-account-collapse {
	overflow: hidden;
	transition: height 0.3s ease-out;
}
.arogga-account-item-group-list {
	margin: 0;
	padding: 0;
}
.arogga-account-item-group-title {
	color: getColor(grey600);
	line-height: 1.5;
	padding: 12px 20px;
	border-bottom: 1px solid #dedede;
}
.arogga-account-submenu-inline {
	cursor: pointer;
}
.arogga-account-submenu-inline.arogga-account-submenu-open {
	> .arogga-account-submenu-title {
		background-color: $background-color_1;
		color: getColor(white);
		cursor: pointer;
		svg {
			color: getColor(white);
			fill: getColor(white);
		}
	}
}
.arogga-account-item.arogga-account-item-active {
	background-color: $background-color_4;
	color: getColor(primary);
	svg {
		color: getColor(primary);
		fill: getColor(primary);
	}
}

.arogga-account-item-active.arogga-account-item-selected {
	background-color: $background-color_1;
	color: getColor(white);
	svg {
		color: getColor(white);
		fill: getColor(white);
	}
}
.arogga-account-item.arogga-account-item-selected {
	background-color: $background-color_4;
	color: getColor(primary);
	svg {
		color: getColor(primary) !important;
		fill: getColor(primary) !important;
	}
}

.arogga-account-submenu-selected {
	background-color: $background-color_1;
	color: getColor(white);
	font-size: 16px;
	svg {
		color: getColor(rgb(85, 16, 16));
		fill: getColor(white);
	}
}
.arogga-account-item-selected {
	background-color: $background-color_1;
	color: getColor(white);
	svg {
		color: getColor(white);
		fill: getColor(white);
	}
}
.arogga-account-sub.arogga-account-inline .arogga-account-item-selected {
	background-color: $background-color_4;
	color: getColor(primary);
	svg {
		color: getColor(primary) !important;
		fill: getColor(primary) !important;
	}
}
.arogga-account-horizontal.arogga-account-sub {
	min-width: 160px;
	margin-top: 0;
}
.arogga-account-vertical.arogga-account-sub {
	min-width: 160px;
	margin-top: 0;
	padding: 0;
}
.arogga-account-vertical-left.arogga-account-sub {
	min-width: 160px;
	margin-top: 0;
	padding: 0;
}
.arogga-account-vertical-right.arogga-account-sub {
	min-width: 160px;
	margin-top: 0;
	padding: 0;
}
.arogga-account-item {
	margin: 0;
	position: relative;
	display: block;
	padding: 7px 7px 7px 16px;
	white-space: nowrap;
}
.arogga-account-submenu-title {
	margin: 0;
	position: relative;
	display: block;
	padding: 7px 7px 7px 16px;
	white-space: nowrap;
}
.arogga-account-item.arogga-account-item-disabled {
	color: $color_3 !important;
}
.arogga-account-submenu-title.arogga-account-item-disabled {
	color: $color_3 !important;
}
.arogga-account-item.arogga-account-submenu-disabled {
	color: $color_3 !important;
}
.arogga-account-submenu-title.arogga-account-submenu-disabled {
	color: $color_3 !important;
}
.arogga-account-item-divider {
	height: 1px;
	margin: 1px 0;
	overflow: hidden;
	padding: 0;
	line-height: 0;
	background-color: $background-color_2;
}
.arogga-account-submenu-popup {
	position: absolute;
	.submenu-title-wrapper {
		padding-right: 20px;
	}
}

.arogga-account-submenu {
	> .arogga-account {
		background-color: $background-color_3;
	}
}

.arogga-account-inline {
	// padding: 12px 0;
	> .arogga-account-item {
		padding: 12px 20px 12px 24px;
	}
	> .arogga-account-submenu {
		> .arogga-account-submenu-title {
			padding: 13px 20px 13px 24px;
			height: 50px;
		}
	}
	.arogga-account-submenu-arrow {
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		vertical-align: baseline;
		text-align: center;
		text-transform: none;
		text-rendering: auto;
		line-height: 1.5em;
		// transform: rotate(90deg);
		transition: transform 0.3s;
		&:before {
			content: '\f0da';
		}
	}
	.arogga-account-submenu-open {
		> .arogga-account-submenu-title {
			.arogga-account-submenu-arrow {
				transform: rotate(90deg);
			}
		}
	}
}

.arogga-account-sub.arogga-account-inline {
	padding: 0;
	border: none;
	border-radius: 0;
	box-shadow: none;
	> .arogga-account-item {
		padding-top: 13px;
		padding-bottom: 13px;
		padding-right: 0;
		border-bottom: 1px solid color(grey300);
		display: flex;
		align-items: center;
		gap: 13px;
	}
	> .arogga-account-submenu {
		> .arogga-account-submenu-title {
			padding-top: 8px;
			padding-bottom: 8px;
			padding-right: 0;
		}
	}
}
.arogga-account-open-slide-up-enter {
	animation-duration: 0.3s;
	animation-fill-mode: both;
	transform-origin: 0 0;
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-play-state: paused;
}
.arogga-account-open-slide-up-appear {
	animation-duration: 0.3s;
	animation-fill-mode: both;
	transform-origin: 0 0;
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-play-state: paused;
}
.arogga-account-open-slide-up-leave {
	animation-duration: 0.3s;
	animation-fill-mode: both;
	transform-origin: 0 0;
	opacity: 1;
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
	animation-play-state: paused;
}
.arogga-account-open-slide-up-enter.arogga-account-open-slide-up-enter-active {
	animation-name: rcMenuOpenSlideUpIn;
	animation-play-state: running;
}
.arogga-account-open-slide-up-appear.arogga-account-open-slide-up-appear-active {
	animation-name: rcMenuOpenSlideUpIn;
	animation-play-state: running;
}
.arogga-account-open-slide-up-leave.arogga-account-open-slide-up-leave-active {
	animation-name: rcMenuOpenSlideUpOut;
	animation-play-state: running;
}
.arogga-account-open-zoom-enter {
	opacity: 0;
	animation-duration: 0.3s;
	animation-fill-mode: both;
	transform-origin: 0 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-play-state: paused;
}
.arogga-account-open-zoom-appear {
	opacity: 0;
	animation-duration: 0.3s;
	animation-fill-mode: both;
	transform-origin: 0 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-play-state: paused;
}
.arogga-account-open-zoom-leave {
	animation-duration: 0.3s;
	animation-fill-mode: both;
	transform-origin: 0 0;
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
	animation-play-state: paused;
}
.arogga-account-open-zoom-enter.arogga-account-open-zoom-enter-active {
	animation-name: rcMenuOpenZoomIn;
	animation-play-state: running;
}
.arogga-account-open-zoom-appear.arogga-account-open-zoom-appear-active {
	animation-name: rcMenuOpenZoomIn;
	animation-play-state: running;
}
.arogga-account-open-zoom-leave.arogga-account-open-zoom-leave-active {
	animation-name: rcMenuOpenZoomOut;
	animation-play-state: running;
}
.arogga-account-submenu-rtl.arogga-account-open-zoom-enter {
	transform-origin: top right !important;
}
.arogga-account-submenu-rtl.arogga-account-open-zoom-appear {
	transform-origin: top right !important;
}
.arogga-account-submenu-rtl.arogga-account-open-zoom-leave {
	transform-origin: top right !important;
}
