@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';
.radioLabel {
	display: inline-block;
	margin-right: 10px;
	cursor: pointer;

	input[type='radio'] {
		margin-right: 5px;
	}

	&.solid {
		padding: 5px 15px;
		border: 1px solid #ccc;
		border-radius: 5px;
		background-color: white;
		transition: background-color 0.3s;

		&:hover {
			background-color: rgba(0, 0, 0, 0.04);
		}

		input[type='radio']:checked + span {
			background-color: #1890ff;
			color: white;
			border-radius: 4px;
			padding: 5px 15px;
		}
	}
}

.radioContainer {
	display: inline-flex;
	align-items: center;
	cursor: pointer;

	.radioInput {
		display: none; // Hide the default radio input

		// Styles when radio is checked
		&:checked + .radioPill {
			background-color: getColor(primary);
			color: white;
			border: none;

			&::before {
				background-color: white; // Inner circle will be white when checked
			}
		}
	}

	.radioPill {
		display: inline-block;
		margin-right: 10px; // Spacing between pill and label text
		width: 24px;
		height: 24px;
		border: 2px solid #ccc;
		border-radius: 50%; // Makes it circle-shaped
		background-color: white; // Default background for the pill
		transition: background-color 0.3s ease;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 10px; // Adjust the size of the inner circle as you like
			height: 10px;
			border-radius: 50%;
			background-color: transparent; // By default, the inner circle has no background
			transition: background-color 0.3s ease;
		}
	}
}
