@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.card {
	background: color(white);
	border-radius: 12px;
	position: relative;
	transition: all 0.3s;
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.05);

	&.noColor {
		background: transparent;
	}

	&.small {
		// Styles for small cards
		.cardHeader {
			padding: 12px 16px;
		}
	}

	&.medium {
		// Styles for medium cards (you can leave this empty if medium is the default size)
	}

	&.large {
	}
	&:hover {
		border-color: #d9d9d9;
	}

	&.bordered {
		border: 1px solid color(grey300);
	}
}

.cardHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid color(grey300);
	padding: 16px 20px;

	.cardTitle {
		font-size: 16px;
		font-weight: 400;
		color: getColor(grey900);
		margin-bottom: 0;
		margin-right: 16px;
	}

	.cardExtra {
		font-size: 14px;
		// color: #1890ff;
		cursor: pointer;
		transition: color 0.3s;

		&:hover {
			// color: #40a9ff;
		}
	}
}

.cardContent {
	color: getColor(grey900);
	margin-bottom: 16px;
	padding: 10px;
}

.cardFooter {
	border-top: 1px solid #f0f0f0;
	font-size: 14px;
}

.cardLoading {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 160px; // adjust based on your needs
	color: rgba(0, 0, 0, 0.45);
}

//hover background white
.card:hover {
	background-color: white;
}
