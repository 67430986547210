.most_love_brands {
	.most_love_brands_card {
		padding: 10px;
		background-color: getColor(secondary_bg);
		display: flex;
		justify-content: center;
		align-items: center;

		.card__image {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		.card__content {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 10px;
			padding-inline: 15px;
			gap: 20px;
		}
	}
}
