@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.slider_text_wrapper {
	overflow: hidden;
	position: absolute;
	width: 100%;
	left: 20px;
    pointer-events: none; 

	@keyframes slideUp {
		0% {
			top: 100%;
			visibility: visible;
		}
		10% {
			top: 0;
		}
		20% {
			top: -100%;
			visibility: hidden;
		}
		100% {
			top: 100%;
			visibility: hidden;
		}
	}

	.common {
		position: absolute;
		top: 100%;
		left: 0;
		animation: ease 0s infinite normal forwards running slideUp;
		font-weight: 400;
		line-height: 18px;
		color: $dark;
		color: getColor(grey600);
	}
}
