@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

@keyframes slideFade {
	from {
		opacity: 0;
		transform: translateY(-10px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
@keyframes slideFadeBottomRight {
	from {
		opacity: 0;
		transform: translate(10px, 10px);
	}
	to {
		opacity: 1;
		transform: translate(0, 0);
	}
}
.dropdown {
	position: relative;
	display: inline-block;

	&:hover {
		.dropdown-menu {
			display: block;
		}
	}
}

.dropdown-toggle {
	cursor: pointer;
	transition: all 0.3s;
	color: getColor(grey900);
	&:hover {
		// background-color: #f5f5f5;
	}
}

.dropdown-menu {
	// display: none;
	position: absolute;
	top: 100%;
	border-radius: 6px;
	background-color: #fff;
	box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
	z-index: 10;
	width: max-content;
	min-width: 100%;
	animation: slideFade 0.3s forwards;
	&.bottomRight {
		left: auto;
		right: 0;
	}

	&.bottomLeft {
		left: 0;
		right: auto;
	}
	.disabled {
		// opacity: 0.5;
	}

	.selected {
		background-color: getColor(p_bg);
		color: getColor(primary);
		font-weight: 500;
		cursor: not-allowed;
	}

	div {
		padding: 13px 20px;
		cursor: pointer;
		transition: all 0.3s;
		color: getColor(grey900);
		font-weight: 400;
		transform: scale(1);
		transition: transform 0.2s;
		&:hover {
			background-color: getColor(p_bg);
			color: getColor(primary);
			// transform: scale(1.05);
		}

		//first-child hover
		&:first-child:hover {
			border-radius: 6px 6px 0 0;
		}
		/// border-bottom without last child
		&:not(:last-child) {
			border-bottom: 1px solid #eeeff2;
		}
	}
}
