$color_1: #666;
$color_2: #999;
$color_3: #777;
$color_4: color(primary_bg);
$background-color_1: color(primary);
$background-color_2: #e7f8f3;
$background-color_3: #fff;
$background-color_4: color(primary_bg);

@keyframes rcMenuOpenSlideUpIn {
	0% {
		opacity: 0;
		transform-origin: 0% 0%;
		transform: scaleY(0);
	}
	100% {
		opacity: 1;
		transform-origin: 0% 0%;
		transform: scaleY(1);
	}
}
@keyframes rcMenuOpenSlideUpOut {
	0% {
		opacity: 1;
		transform-origin: 0% 0%;
		transform: scaleY(1);
	}
	100% {
		opacity: 0;
		transform-origin: 0% 0%;
		transform: scaleY(0);
	}
}
@keyframes rcMenuOpenZoomIn {
	0% {
		opacity: 0;
		transform: scale(0, 0);
	}
	100% {
		opacity: 1;
		transform: scale(1, 1);
	}
}
@keyframes rcMenuOpenZoomOut {
	0% {
		transform: scale(1, 1);
	}
	100% {
		opacity: 0;
		transform: scale(0, 0);
	}
}
.arogga-sidebar-item-selected,
.arogga-sidebar-submenu-title {
	a {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 200px;
	}
}
.arogga-sidebar {
	outline: none;
	margin-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	list-style: none;
	margin-top: 0;
	padding-inline: 10px;
	font-size: 16px;
	border-radius: 3px;
	color: getColor(grey700);
	font-weight: 400;
	ul {
		margin-block-start: 0;
	}
	> li.arogga-sidebar-submenu {
		padding: 0;
	}
	.arogga-sidebar-submenu-selected .arogga-sidebar-submenu-title {
		svg {
			width: 24px;
			height: 24px;
			fill: color(white);
		}
	}
	.arogga-sidebar-submenu-title {
		svg {
			width: 24px;
			height: 24px;
			fill: color(grey600);
		}
	}

	.arogga-sidebar-sub .arogga-sidebar-item-selected {
		// background-color: $background-color_4;
		color: getColor(primary);
		font-size: 16px;
		svg {
			color: getColor(primary);
			fill: color(primary);
		}
	}

	.arogga-sidebar-item {
		padding-block: 10px;
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 18px;
		// border-bottom: 1px solid color(grey300);
		svg {
			width: 24px;
			height: 24px;
			color: #d9d9d9;
			fill: color(grey600);
		}
	}
	.arogga-sidebar-sub .arogga-sidebar-item {
		padding-left: 25px !important;
		padding-right: 20px !important;
	}
}

.arogga-sidebar-sub.arogga-sidebar-inline {
	padding: 0;
	border: none !important;
}
.arogga-sidebar-hidden {
	display: none;
}
.arogga-sidebar-submenu-hidden {
	display: none;
}
.arogga-sidebar-collapse {
	overflow: hidden;
	transition: height 0.3s ease-out;
}
.arogga-sidebar-item-group-list {
	margin: 0;
	padding: 0;
}
.arogga-sidebar-item-group-title {
	color: getColor(grey600);
	line-height: 1.5;
	padding: 12px 20px;
	// border-bottom: 1px solid #dedede;
}
.arogga-sidebar-item.arogga-sidebar-item-active {
	// background-color: $background-color_4;
	color: getColor(primary);
	svg {
		color: getColor(primary);
		fill: color(primary);
	}
}

.arogga-sidebar-item-active {
	// background-color: $background-color_4;
	color: getColor(primary);
	svg {
		color: getColor(primary);
		fill: color(primary);
	}
}
.arogga-sidebar-submenu-active {
	> .arogga-sidebar-submenu-title {
		// background-color: $background-color_2;
		color: getColor(primary);
		svg {
			color: getColor(primary) !important;
			fill: color(primary) !important;
		}
		cursor: pointer;
	}
}
// .arogga-sidebar-item-selected {
// 	background-color: $background-color_1;
// 	transform: translateZ(0);
// }
.arogga-sidebar-submenu-selected {
	background-color: white;
	color: getColor(primary);
	font-size: 16px;
	svg {
		fill: color(grey700) !important;
	}
}
.arogga-sidebar-root {
	.arogga-sidebar-submenu-open.arogga-sidebar-submenu-selected {
		> .arogga-sidebar-submenu-title {
			background-color: $background-color_4;
			color: getColor(primary);
			svg {
				color: getColor(primary) !important;
				fill: color(primary) !important;
			}
			cursor: pointer;
		}
	}
}
// .arogga-sidebar
// 	> .arogga-sidebar-submenu.arogga-sidebar-submenu-inline.arogga-sidebar-submenu-open.arogga-sidebar-submenu-selected:first-child {
// 	> .arogga-sidebar-submenu-title {
// 		background-color: $background-color_4;
// 		color: getColor(primary);
// 		svg {
// 			color: getColor(primary) !important;
// 			fill: color(primary) !important;
// 		}
// 		cursor: pointer;
// 	}
// }

.arogga-sidebar.arogga-sidebar-sub {
	margin-left: 10px;

	img {
		display: none;
	}
}
.arogga-sidebar-horizontal.arogga-sidebar-sub {
	min-width: 160px;
	margin-top: 0;
}
.arogga-sidebar-vertical.arogga-sidebar-sub {
	min-width: 160px;
	margin-top: 0;
	padding: 0;
}
.arogga-sidebar-vertical-left.arogga-sidebar-sub {
	min-width: 160px;
	margin-top: 0;
	padding: 0;
}
.arogga-sidebar-vertical-right.arogga-sidebar-sub {
	min-width: 160px;
	margin-top: 0;
	padding: 0;
}
.arogga-sidebar-item {
	margin: 0;
	position: relative;
	display: block;
	padding: 7px 7px 7px 20px;
	white-space: nowrap;
}
.arogga-sidebar-submenu-title {
	margin: 0;
	position: relative;
	display: block;
	padding: 7px 7px 7px 20px;
	white-space: nowrap;
	//ellipsis
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
	max-width: 300px;
}
.arogga-sidebar-item.arogga-sidebar-item-disabled {
	color: $color_3 !important;
}
.arogga-sidebar-submenu-title.arogga-sidebar-item-disabled {
	color: $color_3 !important;
}
.arogga-sidebar-item.arogga-sidebar-submenu-disabled {
	color: $color_3 !important;
}
.arogga-sidebar-submenu-title.arogga-sidebar-submenu-disabled {
	color: $color_3 !important;
}
.arogga-sidebar-item-divider {
	height: 1px;
	margin: 1px 0;
	overflow: hidden;
	padding: 0;
	line-height: 0;
	background-color: $background-color_2;
}
.arogga-sidebar-submenu-popup {
	position: absolute;
	.submenu-title-wrapper {
		padding-right: 20px;
	}
}
.arogga-sidebar-submenu-rtl.arogga-sidebar-submenu-popup {
	.submenu-title-wrapper {
		padding-right: 0;
		padding-left: 20px;
	}
}
.arogga-sidebar-submenu-rtl {
	.arogga-sidebar-submenu-popup {
		.submenu-title-wrapper {
			padding-right: 0;
			padding-left: 20px;
		}
	}

	.arogga-sidebar-inline {
		.arogga-sidebar-submenu-arrow {
			display: inline-block;
			font: normal normal normal 14px/1 FontAwesome;
			font-size: inherit;
			vertical-align: baseline;
			text-align: center;
			text-transform: none;
			text-rendering: auto;
			line-height: 1.5em;
			// transform: rotate(90deg);
			transition: transform 0.3s;
			&:before {
				content: '\f0da';
			}
		}
		.arogga-sidebar-submenu-open {
			> .arogga-sidebar-submenu-title {
				.arogga-sidebar-submenu-arrow {
					transform: rotate(90deg);
				}
			}
		}
	}
	.arogga-sidebar-vertical.arogga-sidebar-sub {
		direction: rtl;
	}
	.arogga-sidebar-vertical-left.arogga-sidebar-sub {
		direction: rtl;
	}
	.arogga-sidebar-vertical-right.arogga-sidebar-sub {
		direction: rtl;
	}
	.arogga-sidebar-open-zoom-enter {
		transform-origin: top right !important;
	}
	.arogga-sidebar-open-zoom-appear {
		transform-origin: top right !important;
	}
	.arogga-sidebar-open-zoom-leave {
		transform-origin: top right !important;
	}
}
.arogga-sidebar-submenu {
	// border-left: 1px solid color(grey300);
	> .arogga-sidebar {
		background-color: $background-color_3;
	}
}

.arogga-sidebar .arogga-sidebar-item {
	border-bottom: 1px solid color(grey300);
}
.arogga-sidebar .arogga-sidebar-submenu {
	border-bottom: 1px solid color(grey300);
}
.arogga-sidebar-sub .arogga-sidebar-item {
	border-bottom: none;
}
.arogga-sidebar-sub .arogga-sidebar-submenu {
	border-bottom: none;
}

.arogga-sidebar-inline {
	// padding: 12px 0;
	> .arogga-sidebar-item {
		// padding: 12px 20px 12px 24px;

		// border-left: 1px solid color(grey300);
	}
	> .arogga-sidebar-submenu {
		> .arogga-sidebar-submenu-title {
			// padding: 13px 20px 13px 24px;
			height: 50px;
		}
	}
	.arogga-sidebar-submenu-arrow {
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		vertical-align: baseline;
		text-align: center;
		text-transform: none;
		text-rendering: auto;
		line-height: 1.5em;
		// transform: rotate(90deg);
		transition: transform 0.3s;
		&:before {
			content: '\f0da';
		}
	}
	.arogga-sidebar-submenu-open {
		> .arogga-sidebar-submenu-title {
			.arogga-sidebar-submenu-arrow {
				transform: rotate(90deg);
			}
		}
	}
}

.arogga-sidebar-sub.arogga-sidebar-inline {
	padding: 0;
	border: none;
	border-radius: 0;
	box-shadow: none;
	> .arogga-sidebar-item {
		padding-top: 13px;
		padding-bottom: 13px;
		padding-right: 0;
		// border-bottom: 1px solid color(grey300);
		display: flex;
		align-items: center;
		gap: 13px;
		text-wrap: pretty;
	}
	> .arogga-sidebar-submenu {
		> .arogga-sidebar-submenu-title {
			padding-top: 8px;
			padding-bottom: 8px;
			background-color: white;
			// padding-right: 0;
		}
	}
}
.arogga-sidebar-open-slide-up-enter {
	animation-duration: 0.3s;
	animation-fill-mode: both;
	transform-origin: 0 0;
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-play-state: paused;
}
.arogga-sidebar-open-slide-up-appear {
	animation-duration: 0.3s;
	animation-fill-mode: both;
	transform-origin: 0 0;
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-play-state: paused;
}
.arogga-sidebar-open-slide-up-leave {
	animation-duration: 0.3s;
	animation-fill-mode: both;
	transform-origin: 0 0;
	opacity: 1;
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
	animation-play-state: paused;
}
.arogga-sidebar-open-slide-up-enter.arogga-sidebar-open-slide-up-enter-active {
	animation-name: rcMenuOpenSlideUpIn;
	animation-play-state: running;
}
.arogga-sidebar-open-slide-up-appear.arogga-sidebar-open-slide-up-appear-active {
	animation-name: rcMenuOpenSlideUpIn;
	animation-play-state: running;
}
.arogga-sidebar-open-slide-up-leave.arogga-sidebar-open-slide-up-leave-active {
	animation-name: rcMenuOpenSlideUpOut;
	animation-play-state: running;
}
.arogga-sidebar-open-zoom-enter {
	opacity: 0;
	animation-duration: 0.3s;
	animation-fill-mode: both;
	transform-origin: 0 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-play-state: paused;
}
.arogga-sidebar-open-zoom-appear {
	opacity: 0;
	animation-duration: 0.3s;
	animation-fill-mode: both;
	transform-origin: 0 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-play-state: paused;
}
.arogga-sidebar-open-zoom-leave {
	animation-duration: 0.3s;
	animation-fill-mode: both;
	transform-origin: 0 0;
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
	animation-play-state: paused;
}
.arogga-sidebar-open-zoom-enter.arogga-sidebar-open-zoom-enter-active {
	animation-name: rcMenuOpenZoomIn;
	animation-play-state: running;
}
.arogga-sidebar-open-zoom-appear.arogga-sidebar-open-zoom-appear-active {
	animation-name: rcMenuOpenZoomIn;
	animation-play-state: running;
}
.arogga-sidebar-open-zoom-leave.arogga-sidebar-open-zoom-leave-active {
	animation-name: rcMenuOpenZoomOut;
	animation-play-state: running;
}
.arogga-sidebar-submenu-rtl.arogga-sidebar-open-zoom-enter {
	transform-origin: top right !important;
}
.arogga-sidebar-submenu-rtl.arogga-sidebar-open-zoom-appear {
	transform-origin: top right !important;
}
.arogga-sidebar-submenu-rtl.arogga-sidebar-open-zoom-leave {
	transform-origin: top right !important;
}

.arogga-sidebar-sub .arogga-sidebar-submenu {
	border-left: 1px solid color(grey300);
}

.arogga-sidebar-item {
	padding-left: 20px !important;
}
