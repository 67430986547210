//flex box
@function hexToRGB($color) {
	@return rgb(red($color), green($color), blue($color));
}
@function color($key) {
	@return map-get($colors, $key);
}
@mixin flex {
	display: flex;
}

@mixin flexCenter {
	@include flex;
	justify-content: center;
	align-items: center;
}

@mixin itemsCenter {
	@include flex;
	align-items: center;
}
@mixin flexColumn {
	@include flex;
	flex-direction: column;
}
@mixin flexColCenter {
	@include flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
@mixin flexBetween {
	@include flex;
	justify-content: space-between;
}
@mixin flexColBetween {
	@include flex;
	flex-direction: column;
	justify-content: space-between;
}

// utils
@mixin absolute($top: null, $right: null, $bottom: null, $left: null) {
	position: absolute;
	top: $top;
	left: $left;
	right: $right;
	bottom: $bottom;
}

@mixin ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin lastChildMargin {
	&:last-child {
		margin-right: 0;
	}
}
//border
@mixin borderRadius($topLeft: 10px, $topRight: 10px, $bottomRight: 10px, $bottomLeft: 10px) {
	border-top-left-radius: $topLeft;
	border-top-right-radius: $topRight;
	border-bottom-right-radius: $bottomRight;
	border-bottom-left-radius: $bottomLeft;
}

//button
@mixin buttonOutline {
	padding: 8px;
	margin: 10px 0;
	border: 1px solid $primary;
	color: $primary;
	width: 100%;
}

@mixin color($color, $opacity: 1) {
	color: rgba(hexToRGB(map-get($colors, $color)), $opacity);
}
@mixin bgColor($color, $opacity: 1) {
	background-color: rgba(hexToRGB(map-get($colors, $color)), $opacity);
}
@mixin generateBGColor($color) {
	$currentColor: map-get($colors, $color);
	$color: mix(white, $currentColor, 89.98%);
	background-color: $color;
}

@mixin text-transform-utilities {
	.text-lowercase {
		text-transform: lowercase;
	}

	.text-uppercase {
		text-transform: uppercase;
	}

	.text-capitalize {
		text-transform: capitalize;
	}
}
