.card {
	background-color: #ffffff;
	border-radius: 0.375rem; // 6px
	padding-inline: 30px;
	padding-block: 20px;
	border: 1px solid color(grey300);
	&__header {
		// background-color: #f7f7f7; // Light gray background
		padding: 0.75rem 1rem; // Padding around the header
		border-bottom: 1px solid #e5e5e5; // A light border
	}

	&__title {
		font-size: 1.5rem; // 24px
		font-weight: 600;
		margin: 0; // Reset default margins
	}

	&__body {
		padding: 1rem; // Padding around the body
	}

	&__footer {
		background-color: #f7f7f7; // Light gray background
		padding: 0.75rem 1rem; // Padding around the footer
		border-top: 1px solid #e5e5e5; // A light border
		text-align: center; // Center the footer text
	}
}
