@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';
.top__right__user {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	padding: 5px 10px;
	&:hover {
		background-color: getColor(primary_bg);
		border-radius: 10px;
		padding: 5px 10px;
	}
	.top__right__user__icon {
		margin-right: 10px;
	}
}
.top__right__user__name {
	font-size: 14px;
	font-weight: 500;
	color: getColor(grey700);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}
.top__right__user__info__balance {
	display: inline-flex;
	overflow: hidden;
	align-items: center;
	font-weight: 600;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.username_text {
	font-size: 14px;
	font-weight: 500;
	color: getColor(grey700);
	white-space: nowrap;
	overflow: hidden;
	max-width: 200px;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
	line-height: 106%;
	color: #4a5568;
}
