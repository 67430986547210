@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.social_login_wrapper {
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	width: 100%;
	.top {
		display: flex;
		align-items: center;
		width: 100%; /* Ensures it spans the full container */

		hr {
			flex: 1; /* Takes up all available space */
			border: none;
			border-top: 1px solid #ccc;
			margin: 0 10px;
		}

		span {
			padding: 0 10px;
			white-space: nowrap; /* Ensures "or" doesn't wrap */
		}
	}
	.login_buttons {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		margin: 15px 0 20px 0;
		div {
			margin: 0 10px;
			button {
                cursor: pointer;
				width: 40px;
				height: 40px;
				overflow: hidden;
				border-radius: 8px;
				background-color: $white;
				border: 1px solid $grey200;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: all 0.3s ease;
                &:hover {
                    background-color: $grey100;
                }
			}
		}
	}
}
