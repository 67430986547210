// Empty.module.scss
@import '@/services/scss/variables.scss';

.emptyContainer {
	margin-inline: 8px;
	font-size: 14px;
	line-height: 1.5714285714285714;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.emptyImage {
	height: 100px;
	margin-bottom: 30px;
	opacity: 1;
}

.emptyDescription {
	margin-top: 16px;
	font-size: 20px;
	color: getColor(grey900);
	font-weight: 700;
}

.emptyButton {
	margin-top: 16px;
	width: 100%;
}
