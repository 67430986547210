@import '@/services/scss/variables.scss';
.input {
	width: 100%;
	border: 1px solid getColor(grey300);
	border-radius: 10px;
	padding: 10px 16px;
	transition:
		border 0.1s ease-in,
		outline 0.1s ease-out;
	font-size: 16px;
	line-height: 150%;
	// remove number input arrow
	&[type='number'] {
		-moz-appearance: textfield;
		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}

	&[type='date'] {
		&::-webkit-calendar-picker-indicator {
			color: getColor(grey500);
		}

		color: getColor(grey500);
		&::placeholder {
			color: getColor(grey500);
		}
	}
	&:disabled {
		background: getColor(grey100);
		border: 1px solid getColor(grey300);
		color: getColor(grey500);
	}

	&:focus {
		border: 1px solid getColor(primary);
		outline: none;
	}
	&::placeholder {
		color: getColor(grey500);
		font-weight: 300;
		font-size: 16px;
		line-height: 150%;
	}
}

.fileInput {
	[type='file'] {
		display: none;
	}
	display: flex;
	justify-content: center;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	.fileInputLabel {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 10px;
		.fileInputIcon {
			background: getColor(primary);
			border-radius: 10px;
			padding: 12px 12px;
			//affter add text content: "Add File" to the icon
			&::after {
				content: 'Add File';
				text-wrap: nowrap;
				color: white;
				font-size: 14px;
				height: 45px;
				line-height: 150%;
				background-image: url('../../../assets/svg/pdf.svg') no-repeat left center;
				padding-left: 20px; /* Adjust as needed */
				background-size: 16px 16px; /* Adjust size as needed */
				cursor: pointer;
			}
		}
		.fileInputText {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			color: getColor(gray600);
			line-height: 150%;
			gap: 10px;
			&::after {
				display: block;
				color: getColor(gray600);
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				max-width: 200px;
				line-height: 150%;
			}
		}
	}
}
