.spinnerWrapper {
	position: relative;
}

.spinnerOverlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(255, 255, 255, 0.8);
	z-index: 1000;
}

.fullscreen {
	position: fixed;
	background: rgba(0, 0, 0, 0.5);
}

.spinner {
	display: flex;
	justify-content: space-around;
	align-items: center;
	width: 80px;
	height: 80px;
}

.spinner > div {
	width: 18px;
	height: 18px;
	background-color: #1890ff;
	border-radius: 100%;
	animation: spinner-bounce 1.4s infinite ease-in-out both;
}

.spinner > div:nth-child(2) {
	animation-delay: -0.32s;
}

.spinner > div:nth-child(3) {
	animation-delay: -0.16s;
}

@keyframes spinner-bounce {
	0%,
	80%,
	100% {
		transform: scale(0);
	}
	40% {
		transform: scale(1);
	}
}

.tip {
	margin-top: 10px;
	text-align: center;
}

// Sizes
.small .spinner {
	width: 40px;
	height: 40px;
	.spinner > div {
		width: 9px;
		height: 9px;
	}
}

.default .spinner {
	// Styles for default size, already defined above
}

.large .spinner {
	width: 120px;
	height: 120px;
	.spinner > div {
		width: 30px;
		height: 30px;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
@keyframes fadeInOut {
	0%,
	100% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

.spinO {
	animation: fadeInOut 2s linear infinite;
	transform-origin: 30% 20%; /* Center of the element */
}
