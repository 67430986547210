@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.alert {
	padding-top: 20px;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 300;
	line-height: 160%;
	display: flex;
	align-items: center;
	gap: 10px;
}

.success {
	color: getColor(grey600);
	font-weight: 400;
	display: flex;
	svg {
		fill: getColor(error);
	}
}

.error {
	color: getColor(error);
	font-weight: 400;
	svg {
		fill: getColor(error);
	}
}

.warning {
	color: getColor(error);
	display: flex;
	font-weight: 500;
}
