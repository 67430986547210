@import '@/services/scss/variables.scss';
@import '@/services/scss/mixin.scss';

.accordion {
	border-radius: 6px;
	margin: 8px 0;
}

.accordionTitle {
	cursor: pointer;
	padding: 8px 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.accordionContent {
	padding: 16px;
}
.input:checked + label + .accordionContent {
	max-height: 100px; // Adjust this value based on your content’s height
}
